import { Pipe, PipeTransform } from '@angular/core';
import { PickDeep } from 'type-fest';
import { Fleet } from '../../../dashboard/fleet/common/interfaces/fleet';

@Pipe({
  name: 'vehicleIdentifier',
  standalone: true,
})
export class VehicleIdentifierPipe implements PipeTransform {
  public transform(
    identifier:
      | PickDeep<
          Fleet,
          | 'generalInformation.sideNumber'
          | 'generalInformation.registrationNumber'
        >
      | Pick<Fleet['generalInformation'], 'sideNumber' | 'registrationNumber'>,
    format: 'short' | 'long' = 'long',
  ): string {
    if (!identifier) return '';

    const { sideNumber, registrationNumber } =
      'generalInformation' in identifier
        ? identifier.generalInformation
        : identifier;

    if (format === 'short' || !sideNumber) {
      return sideNumber || registrationNumber;
    }
    return `${registrationNumber} · ${sideNumber}`;
  }
}
